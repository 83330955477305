/**
 * 将数组分割成等分的块
 * chunk([1, 2, 3, 4, 5], 2); // [[1, 2], [3, 4], [5]]
 * @param arr
 * @param size
 */
export const chunk = <T>(arr: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
};

/**
 * 将数组分割成指定的块
 *
 * 示例：chunkIntoN([1, 2, 3, 4, 5, 6, 7], 4); // [[1, 2], [3, 4], [5, 6], [7]]
 * @param arr
 * @param n
 */
export const chunkIntoN = <T>(arr: T[], n: number): T[][] => {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (v, i) => arr.slice(i * size, i * size + size));
};

/**
 * 将数组分割成任意大小的块
 *
 * 示例： chunkAnySizes([1, 2, 3, 4, 5, 6, 7, 8], [1, 3, 1, 4, 1]); // [[1], [2, 3, 4], [5], [6, 7]]
 * @param arr
 * @param sizes
 */
export const chunkAnySizes = <T>(arr: T[], sizes: number[]): T[][] => {
  const tempArr = [...arr];
  let start = 0;
  return Array.from(sizes, (v, i) => {
    const res = tempArr.splice(0, v);
    start += v;
    return res;
  }).filter((item) => !!item.length);
};

/**
 * If the item matching the condition already exists
 * in the list it will be removed. If it does not it
 * will be added.
 */
export const toggle = <T>(
  list: readonly T[],
  item: T,
  /**
   * Converts an item of type T item into a value that
   * can be checked for equality
   */
  toKey?: null | ((item: T, idx: number) => number | string | symbol),
  options?: {
    strategy?: 'prepend' | 'append';
  },
) => {
  if (!list && !item) return [];
  if (!list) return [item];
  if (!item) return [...list];
  const matcher = toKey ? (x: T, idx: number) => toKey(x, idx) === toKey(item, idx) : (x: T) => x === item;
  const existing = list.find(matcher);
  if (existing) return list.filter((x, idx) => !matcher(x, idx));
  const strategy = options?.strategy ?? 'append';
  if (strategy === 'append') return [...list, item];
  return [item, ...list];
};
