import dayjs, { ConfigType } from 'dayjs';

export const time = (value: number, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) return undefined;
  if (String(value).length <= 10) {
    value *= 1000;
  }
  return dayjs(value).format(format);
};

// 土语区默认时间格式转换：日-月-年 时:分:秒
export const timeTr = (value: number, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (!value) return undefined;
  if (String(value).length <= 10) {
    value *= 1000;
  }
  return dayjs(value).format(format);
};

export const unix = (value: ConfigType) => {
  return dayjs(value || 0).unix();
};

function calc(ms: number) {
  return function (quantity: number) {
    return Math.round(ms * quantity);
  };
}

export const timeMilliseconds = {
  seconds: calc(1e3),
  minutes: calc(6e4),
  hours: calc(36e5),
  days: calc(864e5),
  weeks: calc(6048e5),
  months: calc(26298e5),
  years: calc(315576e5),
};

export const getCurrentMonthDayCount = (timestamp: number) => {
  // return dayjs(timestamp).daysInMonth();
  const timezone = 8; // 目标时区时间，东八区
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const date = new Date(timestamp + offsetGMT * 60 * 1000 + timezone * 60 * 60 * 1000);
  const month = date.getMonth();
  if (month === 11) {
    const year = date.getFullYear() + 1;
    date.setFullYear(year, 0, 0);
  } else {
    date.setMonth(month + 1, 0);
  }
  return date.getDate();
};

// 获取本月最后一秒  做月倒计时
export const getMonthLastTime = (timestamp: number) => {
  // 一月共有多少天
  const days = getCurrentMonthDayCount(timestamp);
  const lastTime = timestamp + days * 24 * 60 * 60 * 1000;

  return lastTime;
};
