import ActivityButton from '@components/activity/src/components/ActivityButton';
import Spacing from '@components/activity/src/components/Spacing';
import { useStarkTranslation } from '@falla/hooks/src';
import { reload } from '@falla/utils/src/common-util';
import classNames from 'classnames';
import React from 'react';
import { TFunction } from 'react-i18next';

import Button, { ButtonTypes } from '../../Button';
import styles from './index.module.less';

type ThemeTypes = 'default' | 'gray' | 'app' | 'appFull';

export interface ErrorContentProps {
  size?: 'normal' | 'small' | 'smallest';
  type?: 'errorEmpty' | 'errorRequest';
  theme?: ThemeTypes;
  // eslint-disable-next-line react/no-unused-prop-types
  onRefresh?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  showCover?: boolean;
}

const renderText = (props: ErrorContentProps, t: TFunction) => {
  return <div>{t('errorEmptyMessage', 'No data')}</div>;
};

const renderErrorText = (props: ErrorContentProps, t: TFunction) => {
  const { size, theme, showCover = true } = props;
  if (['normal', 'small'].includes(size) || (theme === 'appFull' && showCover)) {
    return (
      <React.Fragment>
        <div>{t('toastApiError', 'Error request, please try again later')}</div>
        <Button
          type={ButtonTypes.default}
          plain
          className={styles.refreshButton}
          onClick={() => {
            if (!props.onRefresh) return reload();
            props.onRefresh();
          }}
        >
          {t('refresh', 'Refresh')}
        </Button>
      </React.Fragment>
    );
  }

  return (
    <div className={styles.inlineError}>
      <div>{t('toastApiError', 'Error request, please try again later')}</div>
      <Spacing width={16} />
      <ActivityButton
        backgroundImage={require('./assets/refresh.png')}
        width={40}
        height={40}
        onClick={() => {
          if (!props.onRefresh) return reload();
          props.onRefresh();
        }}
      />
    </div>
  );
};

const Index: React.FC<ErrorContentProps> = (props) => {
  const { type, children } = props;
  const { t } = useStarkTranslation();

  return (
    <div
      className={classNames(styles.errorContent, {
        [styles.isNormal]: props.size === 'normal',
        [styles.isSmall]: props.size === 'small',
        [styles.isSmallest]: props.size === 'smallest',
        [styles.isAppFullLarge]: props.theme === 'appFull',
      })}
    >
      {children}
      {type === 'errorRequest' && !children && renderErrorText(props, t)}
      {type === 'errorEmpty' && !children && renderText(props, t)}
    </div>
  );
};

Index.defaultProps = {
  size: 'normal',
  type: 'errorEmpty',
};

export default Index;
