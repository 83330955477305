import React from 'react';

import DataError, { AppDataErrorProps, DataErrorType } from '../DataError';

type MyPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

type MyOmit<T, K extends keyof any> = MyPick<T, Exclude<keyof T, K>>;

export type DataStatusProps = {
  error?: boolean;
  empty?: boolean;
} & MyOmit<AppDataErrorProps, 'type'>;

export const DataStatus: React.FC<DataStatusProps> = (props) => {
  const { error, empty } = props;

  if (error) {
    return <DataError key={'error'} type={DataErrorType.request} {...props} />;
  }

  if (empty) {
    return <DataError key={'empty'} type={DataErrorType.empty} {...props} />;
  }

  return null;
};

export default DataStatus;
