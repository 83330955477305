import { getBaseURL } from '@falla/request-service/src/request.config';
import { combineURLs } from '@falla/utils/src/string-util';
import { request } from 'ice';

import type { FetchOrderInfoParams, FetchOrderInfoRes } from '@/apis/types';

export const orderApis = {
  fetchOrderInfo: (params: FetchOrderInfoParams) => {
    const url = combineURLs(getBaseURL(), '/api/web/payment/order/infoByPaymentId');
    return request.post<FetchOrderInfoRes>(url, params);
  },
};
