/**
 * useAnalytics
 *
 * @author fukui
 */
import { getQueryParams } from '@falla/utils/src/common-util';
import React from 'react';

export const useGaReport = () => {
  React.useEffect(() => {}, []);

  const reportPageShow = () => {
    if (!window.customEvent) return;
    const source = getQueryParams('_source');

    window.customEvent({
      eventName: 'page_view',
      eventParams: {},
    });
    if (source) {
      window.customEvent({
        eventName: `${source}_page_view`,
        eventParams: {},
      });
    }
  };

  const click = ({ element }: { element: string }) => {
    if (!window.customEvent) return;
    window.customEvent({
      eventName: `${element}_click`,
    });
  };

  return {
    click,
    reportPageShow,
    customEvent: window.customEvent,
  };
};

export default useGaReport;
