import { useFromSource } from '@/hooks/useFromSource';

import styles from './index.module.less';

interface Props {}

function Index(props: Props) {
  const { isFromPay } = useFromSource();

  if (!isFromPay) return <></>;
  return (
    <div className={styles.appHeader}>
      <div className={styles.body}>
        <img className={styles.logo} src={'https://www.joymi.live/favicon.png'} />
        <div className={styles.name}>JoyMi</div>
      </div>
    </div>
  );
}

export default Index;
