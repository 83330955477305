/* eslint-disable no-param-reassign */
/**
 * axios.config
 *
 * @author fukui
 */
import Toast from '@components/base/src/components/Toast';
import appBridge from '@falla/app-bridge/src';
import config, { APP_MODE_VALUE } from '@falla/config/src/config';
import { ApiErrorCode, DEFAULT_ERROR_MSG_CODE } from '@falla/config/src/constants';
import {
  getUserInfoByChangeAccountPlugin,
  isExistChangeAccountPlugin,
} from '@falla/page-plugins/src/error/vconsole-change-account/index';
import { getQueryParams } from '@falla/utils/src/common-util';
import { getLanguage } from '@falla/utils/src/i18n-util';
import ReportUtil from '@falla/utils/src/report-util';
import { isUrl } from '@falla/utils/src/type-util';
import { AxiosError, AxiosResponse } from 'axios';

import { ICustomRequestConfig, IInterceptors } from './request.config.type';

// const proxy = process.env.NODE_ENV === 'development' && !isInIcestark();
const proxy = false;

const getHost = (env: APP_MODE_VALUE) => {
  const host = proxy ? config.proxyBaseUrl[env] : config.baseUrl[env];
  if (host) return host;
  return (proxy ? config.proxyBaseUrl[config.APP_MODE] : config.baseUrl[config.APP_MODE]) || config.baseUrl.prod;
};

export const getBaseURL = (mock?: boolean): string => {
  if (mock && config.isDev) return config.proxyBaseUrl.mock;
  const queryEnv = getQueryParams('env') as APP_MODE_VALUE;
  if (queryEnv) return getHost(queryEnv);

  const { env, host } = appBridge.getApiEnv();
  const { realVersion } = appBridge.getUserDeviceInfo();

  // APP版本包含了动态域名选择
  const hasDynamicHost = realVersion && host;
  if (hasDynamicHost) {
    if (isUrl(host)) return host;
    return `https://${host}`;
  }

  return getHost(env);
};

const HTTP_STATUS_CODE: { [key: number]: string } = Object.freeze({
  400: '请求错误',
  403: '权限不足拒绝访问',
  404: '接口找不到了',
  500: '服务器内部错误',
  408: '请求超时',
  501: '服务未实现',
  502: '网关错误',
  503: '服务不可用',
  504: '网关超时',
  505: 'HTTP版本不受支持',
});

export const isShowDefaultErrorMsg = (code: number) => {
  return DEFAULT_ERROR_MSG_CODE.includes(code);
};

const getCommonRequestConfig = (): { interceptors: IInterceptors; timeout: number } => {
  const isVConsole = getQueryParams('__debug__') === 'true';
  const userInfo = getUserInfoByChangeAccountPlugin();

  return {
    interceptors: {
      request: {
        onConfig: (conf) => {
          const requestHeaders = appBridge.getRequestHeaders();
          const requestSource = requestHeaders['X-Mac'] ? 'web' : 'h5';
          conf.baseURL = getBaseURL();
          if (!conf.data) conf.data = {};
          if (!conf.params) conf.params = {};
          if (!conf.headers) conf.headers = { 'X-RequestSource': requestSource, 'X-Language': getLanguage() };
          // @ts-ignore
          if (conf?.noRequestHeaders) return conf;
          conf.headers['X-RequestSource'] = requestSource;
          if (requestHeaders['X-Mac']) conf.headers = Object.assign(conf.headers, requestHeaders);

          // 只有测试环境才能使用
          if (isVConsole && isExistChangeAccountPlugin() && userInfo) {
            conf.headers['X-Authorization'] = userInfo.accesstoken;
            conf.headers['X-Uid'] = userInfo.uid;
            conf.headers['X-Mac'] = '999';
            conf.headers['X-Language'] = getLanguage();
          }

          if (!config.isDev) return conf;
          // 非 app 的环境，追加调试 token
          if (!appBridge.getAppVersion() && !Object.keys(conf.headers).includes('X-Uid')) {
            conf.headers['X-Language'] = getLanguage();
            conf.headers['X-Uid'] = '999';
            conf.headers['X-Authorization'] = '999';
            conf.headers['X-Mac'] = '999';
          }
          return conf;
        },
        onError: (error) => {
          return Promise.reject(error);
        },
      },
      response: {
        onConfig: (response: AxiosResponse) => {
          const { data } = response;
          if (data.code !== 0 && data.code !== 999) {
            ReportUtil.report({
              level: ReportUtil.LogType.RET_ERROR,
              msg: `API 响应异常，code: ${data.code}\nmessage: ${data.msg}\n\n${JSON.stringify(
                {
                  config: response.config,
                  headers: response.headers,
                  data: response.data,
                },
                null,
                2,
              )}`,
            });
          }
          // 根据错误code默认展示统一的信息msg
          if (ApiErrorCode.stopTaking === data.code) {
            Toast.show({
              content: data.msg,
            });
          }

          // @ts-ignore
          if (response.config.instanceName === 'withFullResponse') return data;

          if (data.code === 0) return data.res;
          data.message = data.msg || '';

          // eslint-disable-next-line default-case
          switch (data.code) {
            case ApiErrorCode.filterToast:
              Toast.show({
                content: data.message,
              });
              break;
            // token error
            case ApiErrorCode.tokenErr:
              if (!config.isHostProd && isVConsole && isExistChangeAccountPlugin() && userInfo) {
                // 针对 vconsole plugin token 过期情况，
                // 清掉 v1.0_change_account_plugin 的用户信息
                localStorage.removeItem('v1.0_change_account_plugin');
                Toast.show({
                  content:
                    '此账号信息已过期，请切换别的账号，或者将此账号在APP内登陆在试一次。 \n 请手动刷新下页面或者重新切换一个新账号',
                  duration: 15000,
                });
              }

              break;
          }
          return Promise.reject(data);
        },
        onError: (error: AxiosError) => {
          ReportUtil.report({
            level: ReportUtil.LogType.AJAX_ERROR,
            msg: `AJAX 请求异常: \n${error.message}\n\n${JSON.stringify(error.toJSON(), null, 2)}`,
          });
          // 请求出错：服务端返回错误状态码
          let result = {
            code: 400,
            msg: '未知错误',
            error: error.message,
          };
          console.log('response error res:', error);
          if (error.response) {
            const { data = {}, status } = error.response || {};
            result.code = status;
            HTTP_STATUS_CODE[status] ? (result.msg = HTTP_STATUS_CODE[status]) : (result = data);
          }
          // message.error(result.msg || '服务器繁忙');
          return Promise.reject(error);
        },
      },
    },
    timeout: 20000,
  };
};

// 可配置多数组实例：https://ice.work/docs/guide/basic/request
export const requestConfig: ICustomRequestConfig[] = [
  {
    withFullResponse: true,
    ...getCommonRequestConfig(),
  },
  {
    withFullResponse: true,
    instanceName: 'withFullResponse',
    ...getCommonRequestConfig(),
  },
];

export default requestConfig;
