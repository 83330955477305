/**
 * filters
 *
 * @author fukui
 */
import dayjs from 'dayjs';

import { numberToScore } from './format-util';
import { getLanguage } from './i18n-util';
import { separator } from './number-util';
import { leftPade } from './string-util';

export enum cdnZoomEnum {
  // lfit（默认值）：等比缩放，缩放图限制为指定w与h的矩形内的最大图片。
  lfit = 'lfit',
  // mfit：等比缩放，缩放图为延伸出指定w与h的矩形框外的最小图片。
  mfit = 'mfit',
  // fill：将原图等比缩放为延伸出指定w与h的矩形框外的最小图片，之后将超出的部分进行居中裁剪。
  fill = 'fill',
  // pad：将原图缩放为指定w与h的矩形内的最大图片，之后使用指定颜色居中填充空白部分。
  pad = 'pad',
  // fixed：固定宽高，强制缩放。
  fixed = 'fixed',
}

const isCdnZoomImg = function (fileSuffix: string): boolean {
  const reg = /(\.jpg|\.png|\.jpeg|\.webp)$/i;
  return reg.test(fileSuffix);
};

export const cdnImg = (url: string, picWidth: number, picHeight: number, zoom = cdnZoomEnum.pad) => {
  if (!url) return '';
  if (!isCdnZoomImg(url)) return url;
  if (!cdnZoomEnum[zoom] && zoom !== undefined) zoom = cdnZoomEnum.pad;
  let picSize = '';

  if (Number.isInteger(picWidth) && Number.isInteger(picHeight)) {
    picSize = `m_${zoom || cdnZoomEnum.fill},w_${picWidth},h_${picHeight}`;
  } else if (!picHeight && Number.isInteger(picWidth)) {
    picSize = `m_${zoom || cdnZoomEnum.lfit},w_${picWidth}`;
  } else if (!picWidth && Number.isInteger(picHeight)) {
    picSize = `m_${zoom || cdnZoomEnum.lfit},h_${picHeight}`;
  } else if (!picWidth && !picHeight) {
    picSize = `m_${zoom || cdnZoomEnum.fill},h_80,w_80`;
  }

  if (
    !url.includes('vochat.com') &&
    !url.includes('falla.live') &&
    !url.includes('vochatapp.com') &&
    !url.includes('apifalla.com') &&
    !url.includes('fallalive.com') &&
    !url.includes('resygg.com')
  ) {
    return url;
  }
  let index = url.indexOf('?timestamp=');
  if (index === -1) {
    index = url.indexOf('?t=');
  }

  let fmt = `?x-oss-process=image/resize,${picSize}`;

  if (
    url.includes('falla-res1.falla.live') ||
    url.includes('falla-common.falla.live') ||
    url.includes('falla-common1.falla.live') ||
    url.includes('falla-ures2.falla.live') ||
    url.includes('falla-ures1.falla.live') ||
    url.includes('falla-res1.apifalla.com') ||
    url.includes('falla-common.apifalla.com') ||
    url.includes('falla-common1.apifalla.com') ||
    url.includes('falla-ures2.apifalla.com') ||
    url.includes('falla-ures1.apifalla.com') ||
    url.includes('falla-res1.resygg.com') ||
    url.includes('falla-ures1.resygg.com')
  ) {
    fmt += ',color_ffffff00';
  }

  if (index === -1) {
    return url + fmt;
  } else {
    return url.substr(0, index) + fmt;
  }
};

export const score = numberToScore;
export const leftPad = leftPade;
export const time = (ms: number, template = 'YYYY-MM-DD HH:mm:ss') => {
  if (String(ms).length === 10) {
    ms *= 1000;
  }
  return dayjs(ms).format(template);
};

export const trTime = (ms: number) => {
  return time(ms, 'DD/MM/YYYY HH:mm:ss');
};

export const numI18nSeparator = function (num: number, lang?: string) {
  if (!lang) lang = getLanguage();
  if (String(lang).includes('tr')) return separator(num, 3, '.', ',');
  return separator(num);
};
