import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import styles from './index.module.less';
import MaskLoading from './MaskLoading';
import { SpinProps } from './typings';

function Spin(props: SpinProps, ref: any) {
  const { style, className, children, loading: propLoading, tip, indicator, delay } = props;

  const [loading, setLoading] = useState<boolean>(delay ? false : propLoading);
  const debouncedSetLoading = useCallback(debounce(setLoading, delay), [delay]);

  const _usedLoading = delay ? loading : propLoading;

  useEffect(() => {
    delay && debouncedSetLoading(propLoading);
    return () => {
      debouncedSetLoading && debouncedSetLoading.cancel();
    };
  }, [propLoading, debouncedSetLoading, delay]);

  const loadingIcon = (
    <span className={styles.baseSpinIcon}>
      {indicator ? (
        React.cloneElement(indicator as ReactElement, {
          className: styles.baseSpinIconLoading,
          style: {},
        })
      ) : (
        <MaskLoading />
      )}
    </span>
  );

  return (
    <div
      ref={ref}
      className={classNames(
        styles.baseSpin,
        {
          [styles.baseSpinLoading]: _usedLoading,
          [styles.baseSpinWithTip]: tip && !children,
        },
        className,
      )}
      style={style}
    >
      {children ? (
        <>
          <div className={styles.baseSpinChildren}>{children}</div>
          {_usedLoading && (
            <div className={styles.baseSpinLoadingLayer}>
              <span className={styles.baseSpinLoadingLayerInner}>
                {loadingIcon}
                {tip ? <div className={styles.baseSpinTip}>{tip}</div> : null}
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          {loadingIcon}
          {tip ? <div className={styles.baseSpinTip}>{tip}</div> : null}
        </>
      )}
    </div>
  );
}

const SpinComponent = React.forwardRef<unknown, SpinProps>(Spin);

SpinComponent.displayName = 'Spin';

export default SpinComponent;

export { SpinProps };
