import Button, { ButtonTypes } from '@components/app/src/components/Button';
import AppDataError, { DataErrorType } from '@components/app/src/components/DataError';
import { reload } from '@falla/utils/src/common-util';
import { setPageLoading } from '@falla/utils/src/page-util';
import { useMount } from 'ahooks';
import React from 'react';

import styles from './index.module.less';

export default () => {
  useMount(() => {
    setPageLoading(false);
  });
  return (
    <AppDataError
      type={DataErrorType.request}
      className={styles.notFound}
      onRefresh={() => {
        reload();
      }}
    >
      <div>{"Sorry, the page you visited doesn't exist"}</div>
      <Button type={ButtonTypes.default} plain className={styles.refreshButton} onClick={() => reload()}>
        {'Refresh'}
      </Button>
    </AppDataError>
  );
};
