import React from 'react';

import { createLoadingDataStore, Provider } from '../../store';

const LoadingDataProvider: React.FC = ({ children }) => {
  return <Provider createStore={createLoadingDataStore}>{children}</Provider>;
};

const withLoadingData = (Component: React.ComponentType<any>) => (props: any) => {
  return (
    <LoadingDataProvider>
      <Component {...props} />
    </LoadingDataProvider>
  );
};

export { LoadingDataProvider, withLoadingData };
