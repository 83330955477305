/*
 * @Description: 隐私政策
 * @Author: liaoxingfeng@iyinguo.com
 */

import { useStarkTranslation } from '@falla/hooks/src';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import classNames from 'classnames';
import React from 'react';

import AppNameHeader from '@/components/AppNameHeader';
import { useFromSource } from '@/hooks/useFromSource';

import styles from './index.module.less';

interface IProps {}

const PrivacyPolicy: React.FC<IProps> = () => {
  const { t } = useStarkTranslation();
  useDocumentTitle(t('contactus', 'Contact Us'), { restoreOnUnmount: true });
  const { isFromPay } = useFromSource();

  return (
    <div className={classNames(styles.privacyPolicy, { [styles.isFromPay]: isFromPay })}>
      <div className={styles.wrapper}>
        <AppNameHeader />
        <div className={styles.title}>{t('contactus', 'Contact Us')}</div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: t(
              'contactusContent',
              // eslint-disable-next-line max-len
              'Email：thejoymiapp@gmail.com',
            ),
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(PrivacyPolicy);
