import { getQueryParams } from '@falla/utils/src/common-util';
import React from 'react';

export const useFromSource = () => {
  const [source] = React.useState(getQueryParams('source'));
  const isFromPay = React.useMemo(() => {
    return source === 'pay';
  }, [source]);

  return {
    isFromPay,
    source,
  };
};
