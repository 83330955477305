import classNames from 'classnames';
import React from 'react';

import styles from './index.module.less';

interface IProps {
  className?: string;
}

const MaskLoading: React.FC<IProps> = (props) => {
  return (
    <div className={classNames(styles.maskLoading, props.className)}>
      <div className={classNames(styles.svg, styles.baseSpinIconLoading)}>
        <svg viewBox="0 0 72 72" xmlns="https://www.w3.org/2000/svg">
          <g fill="#FFF" fillRule="nonzero">
            {/*eslint-disable*/}
            <path d="M53.8 35.831a8.8 8.8 0 1 0 17.6 0 8.8 8.8 0 0 0-17.6 0ZM24.1 10.6a6.6 6.6 0 1 0 13.2 0 6.6 6.6 0 0 0-13.2 0ZM1 39.2a5.5 5.5 0 1 0 11 0 5.5 5.5 0 0 0-11 0Z" />
            <path
              // eslint-disable
              d="M25.2 61.2a4.404 4.404 0 0 0-4.4 4.4c0 2.427 1.973 4.4 4.4 4.4 2.427 0 4.4-1.973 4.4-4.4 0-2.427-1.973-4.4-4.4-4.4Zm34.499 3.699A9.876 9.876 0 0 1 52.7 67.8c-2.53 0-5.067-.97-6.999-2.901-3.864-3.864-3.864-10.134 0-13.998A9.876 9.876 0 0 1 52.7 48c2.53 0 5.067.97 6.999 2.901 3.87 3.864 3.87 10.134 0 13.998Zm-1.643-43.134a7.816 7.816 0 0 1-5.535 2.296 7.775 7.775 0 0 1-5.534-2.296c-3.06-3.06-3.06-8.016 0-11.069A7.775 7.775 0 0 1 52.52 8.4c2 0 4.008.763 5.535 2.296 3.059 3.053 3.059 8.01 0 11.069ZM17.94 24.247a6.032 6.032 0 0 1-4.283 1.774 6.058 6.058 0 1 1 4.283-10.34 6.058 6.058 0 0 1 0 8.566Zm-1.898 34.402a4.959 4.959 0 0 1-3.499 1.451 4.902 4.902 0 0 1-3.492-1.45 4.942 4.942 0 0 1 0-6.992 4.943 4.943 0 0 1 3.492-1.451 4.93 4.93 0 0 1 3.5 1.45 4.942 4.942 0 0 1 0 6.992Z"
              opacity=".6"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default MaskLoading;
