/**
 * 产生随机字符串
 *
 * @param {Integer} size 生成的长度
 * @param {String} chars 自定义生成字符串的内容
 * @return {String} 生成好的随机字符串
 */
export const randomString = function (size: number, chars?: string) {
  size = size || 6;
  chars = chars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const max = chars.length;
  let ret = '';
  for (let i = 0; i < size; i++) {
    ret += chars.charAt(Math.floor(Math.random() * max));
  }
  return ret;
};

/**
 * 产生随机数字字符串
 *
 * @param {Integer} size 生成的长度
 * @return {String} 生成好的随机字符串
 */
export const randomNumber = function (size: number) {
  return randomString(size, '0123456789');
};

/**
 * 产生随机字母字符串
 *
 * @param {Integer} size 生成的长度
 * @return {String} 生成好的随机字符串
 */
export const randomLetter = function (size: number) {
  return randomString(size, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');
};

export const randomOrderNumber = (): string => {
  const now = new Date();
  let month: number | string = now.getMonth() + 1;
  let day: number | string = now.getDate();
  let hour: number | string = now.getHours();
  let minutes: number | string = now.getMinutes();
  let seconds: number | string = now.getSeconds();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  hour = hour < 10 ? `0${hour}` : hour;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  const orderCode =
    now.getFullYear().toString() +
    month.toString() +
    day +
    hour +
    minutes +
    seconds +
    Math.round(Math.random() * 1000000).toString();
  return orderCode;
};
