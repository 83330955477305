import appBridge from '@falla/app-bridge/src';
import { iConfig } from '@falla/config/src/config';
import Aegis from 'aegis-web-sdk';

import { CustomPackageJson } from '../../types';

enum Environment {
  production = 'production',
  development = 'development',
  gray = 'gray',
  pre = 'pre',
  daily = 'daily',
  local = 'local',
  test = 'test',
  others = 'others',
}

const init = (packageJson: Partial<CustomPackageJson> = {}) => {
  if (iConfig.isDev) return;
  // https://cloud.tencent.com/document/product/1464/58560
  const aegis = new Aegis({
    id: 'LlxjdfjLroyDVDvwDl', // 上报 id
    env: iConfig.isHostProd ? Environment.production : Environment.test,
    uin: appBridge.getAppUid(), // 用户唯一 ID（可选）
    reportApiSpeed: false, // 接口测速
    reportAssetSpeed: packageJson.name === 'app-level', // 静态资源测速
    spa: false, // spa 应用页面跳转的时候开启 pv 计算
    // 设置为空字符串可以不进行 pv 上报。
    pvUrl: '',
    // 设置为空字符串可以不进行页面性能上报。
    webVitals: false,
    hostUrl: 'https://rumt-us.com',
    api: {
      apiDetail: true,
    },
    beforeRequest: (data: { logType: string }) => {
      /*
        { logType: 'custom', name: '自定义测速' }
        { logType: 'event', name: '自定义事件' }
        { logType: 'log', name: '日志' }
        { logType: 'performance', name: '页面测速' }
        { logType: 'pv', name: '页面PV' }
        { logType: 'speed', name: '接口和静态资源测速' }
        { logType: 'vitals', name: 'web vitals' }
       */
      // 白名单、性能、webVitals不做上报
      if (['whiteList', 'webVitals', 'pv'].includes(data?.logType)) return false;
      if (['performance'].includes(data?.logType)) {
        if (packageJson.name !== 'app-level') return false;
      }
      return data;
    },
    version: packageJson?.version,
  });
  // @ts-ignore
  window.aegis = aegis;
};

export default { init };
