import DataStatus, { DataStatusProps } from '@components/app/src/components/DataStatus';
import Spin from '@components/base/src/components/Spin';
import useMergeProps from '@falla/hooks/src/useMergeProps';
import { useDeepCompareEffect, useRequest } from 'ahooks';
import { Options } from 'ahooks/lib/useRequest/src/types';
import React, { memo, PropsWithChildren, useRef } from 'react';

import { useLoadingDataStore } from '../../store';
import { ApiParams, ApiRes, ApiService } from '../../types';

export interface ICommonLoadingDataProps {
  className?: string;
  style?: React.CSSProperties;
  apiService: ApiService<ApiRes, ApiParams | undefined>;
  dataStatusProps?: DataStatusProps;
  requestOptions?: Options<ApiRes, any>;
  customLoading?: boolean;
}

const CommonLoadingData: React.FC<PropsWithChildren<ICommonLoadingDataProps>> = (props) => {
  const { className, style, customLoading } = props;
  const propsRef = useRef(props);
  propsRef.current = props;
  const setState = useLoadingDataStore((state) => state.setState);
  const data = useLoadingDataStore((state) => state.data);
  const loading = useLoadingDataStore((state) => state.loading);
  const error = useLoadingDataStore((state) => state.error);

  const response = useRequest<ApiRes | undefined, [ApiParams] | []>(
    async (params?: ApiParams) => {
      const args = { ...params };
      const res = await propsRef.current.apiService?.(args);
      setState({
        data: res,
      });
      return res;
    },
    {
      ...(props.requestOptions || {}),
    },
  );

  const { refresh } = response;

  useDeepCompareEffect(() => {
    setState({
      ...response,
    });
  }, [response]);

  const dataStatusProps = useMergeProps<DataStatusProps>({}, props.dataStatusProps || {});

  // 当显示 loading 蒙层时候，不展示数据状态
  const showDataStatus = React.useMemo(() => {
    if (error) return true;
    if (loading || customLoading) return false;
    return true;
  }, [loading, error, customLoading]);

  return (
    <Spin loading={loading || customLoading}>
      <div className={className} style={style}>
        {props.children}
        {showDataStatus && (
          <DataStatus
            {...{
              error: !!error,
              empty: !error && !data,
              overlay: true,
              onRefresh: refresh,
              ...dataStatusProps,
            }}
          />
        )}
      </div>
    </Spin>
  );
};

export default memo(CommonLoadingData);
