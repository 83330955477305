import { Result } from 'ahooks/lib/useRequest/src/types';
import { create, StoreApi } from 'zustand';
import createContext from 'zustand/context';

import { ApiParams, ApiRes } from './types';

export interface LoadingDataStore extends Partial<Result<ApiRes, ApiParams[]>> {
  readonly setState: (store: Partial<LoadingDataStore>) => void;
}

export const createLoadingDataStore = () =>
  create<LoadingDataStore>((setState, getState, store) => ({
    setState,
    loading: false,
    error: undefined,
    data: undefined,
  }));

export const { Provider, useStore: useLoadingDataStore, useStoreApi } = createContext<StoreApi<LoadingDataStore>>();
