import {
  CommonLoadingData,
  useLoadingDataStore,
  withLoadingData,
} from '@components/module/src/components/LoadingDataRender';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import { chunkIntoN } from '@falla/utils/src/array-util';
import { getQueryParams } from '@falla/utils/src/common-util';
import { timeMilliseconds, timeTr } from '@falla/utils/src/date-util';
import { numI18nSeparator } from '@falla/utils/src/filter-util';
import { format } from '@falla/utils/src/string-util';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { orderApis } from '@/apis/orderApis';
import type { FetchOrderInfoRes } from '@/apis/types';

import styles from './index.module.less';

interface Props {}

function Index(props: Props) {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (!t('orderTitle', '')) return;
    i18n.changeLanguage('en');
  }, [t]);

  useDocumentTitle(t('orderTitle', ''), { restoreOnUnmount: true });
  const { data: _data } = useLoadingDataStore();
  const data = _data as FetchOrderInfoRes;
  const cardNum = React.useMemo(() => {
    const num = data?.bin6 + data?.last4;
    let list: string[] = [];
    if (num.length === 10) {
      list = `${data?.bin6}******${data?.last4}`.split('');
    } else {
      list = `${data?.bin6}********${data?.last4}`.split('');
    }
    const arr = chunkIntoN(list, 4);
    let res = '';
    arr.forEach((item, index) => {
      if (index === 0) {
        res += item.join('');
      } else {
        res = `${res}-${item.join('')}`;
      }
    });
    return res;
  }, [data]);

  return (
    <CommonLoadingData
      customLoading={!t('title', '')}
      apiService={() => {
        return orderApis.fetchOrderInfo({
          paymentId: getQueryParams('id') as string,
        });
      }}
    >
      <div className={styles.container}>
        {data && t('orderBill', '') && (
          <React.Fragment>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <img className={styles.logo} src={'https://www.joymi.live/favicon.png'} />
              </div>
              <div className={styles.headerRight}>{t('orderBill', '')}</div>
            </div>
            <div className={styles.card}>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderUid', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>{data.suid}</div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderCardholder', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>{data.nickname}</div>
              </div>
              {data.email && (
                <>
                  <div className={styles.dataItem}>
                    <div className={styles.dataItemLabel}>{t('orderEmail', '')}</div>
                    <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>
                      {data.email || '-'}
                    </div>
                  </div>
                  <div className={styles.dataItem} />
                </>
              )}

              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderId', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>{data.orderid}</div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderPayId', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>{data.paymentId}</div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderPayTime', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  {timeTr(data.createtimets * 1000 - timeMilliseconds.hours(5))}
                </div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderShippingTime', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  {timeTr(data.verifytimets * 1000 - timeMilliseconds.hours(5))}
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderPayCardNum', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>{cardNum}</div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderCVV', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>
                  {data.cvvCheck ? 'YES' : 'NO'}
                </div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderBank', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  {data.bank || '-'}
                </div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderChannel', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  {data.orderChannel || '-'}
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderProduct', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  <i
                    className={classNames({
                      [styles.iconCoin]: data.rechargeType === 1,
                      [styles.iconJoCoin]: data.rechargeType === 2,
                    })}
                  />
                  {numI18nSeparator(data.coin, 'tr')}
                </div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderPrice', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: true })}>
                  {data.localPriceShow} {data.localCurrencyShow}
                </div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderProductId', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>{data.identifier}</div>
              </div>
              <div className={styles.dataItem}>
                <div className={styles.dataItemLabel}>{t('orderProductDesc', '')}</div>
                <div className={classNames(styles.dataItemValue, { [styles.isSpecial]: false })}>
                  {format(t('orderProductDescContent', ''), {
                    identifier: data.identifier,
                    coin: data.coin,
                  })}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.tips} dangerouslySetInnerHTML={{ __html: t('orderTips', '') }} />
              <div className={styles.footerLogo}>
                <img src={'https://www.joymi.live/favicon.png'} />
              </div>
              <div className={styles.links}>
                <a href="https://web.joymiweb.com/main/index.html#/app-intro?translateId=447&source=pay">
                  {t('orderLink1', '')}
                </a>
                <a href="https://web.joymiweb.com/main/index.html#/app-intro?translateId=442&source=pay">
                  {t('orderLink2', '')}
                </a>
                <a href="#/privacy-policy?source=pay">{t('orderLink3', '')}</a>
              </div>
              <div className={styles.copyright}>Copyright &copy; 2024 JoyMi</div>
            </div>
          </React.Fragment>
        )}
      </div>
    </CommonLoadingData>
  );
}

export default withLoadingData(Index);
