import Spacing from '@components/activity/src/components/Spacing';
import Button, { ButtonTypes } from '@components/app/src/components/Button';
import { COMMON_EVENT_NAMES, RequestStatus } from '@falla/config/src/constants';
import { useStarkTranslation } from '@falla/hooks/src';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import { getLanguage } from '@falla/utils/src/i18n-util';
import { event } from '@ice/stark-data';
import { useClickAway } from 'ahooks';
import { Image, Swiper } from 'antd-mobile';
import { useHistory } from 'ice';
import React, { useEffect, useState } from 'react';

import { APP_LINK, LANGTYPE_MAPPING } from './common/constants';
import { changeLanguage } from './common/utils';
import styles from './index.module.less';

const SWIPER_IMAGES = [
  require('./assets/swiper/01.png'),
  require('./assets/swiper/02.png'),
  require('./assets/swiper/03.png'),
  require('./assets/swiper/04.png'),
  require('./assets/swiper/05.png'),
  require('./assets/swiper/06.png'),
  require('./assets/swiper/07.png'),
  require('./assets/swiper/08.png'),
];

const Home = () => {
  const { t, i18n } = useStarkTranslation();
  const [language, setLanguage] = useState<string>('');
  const [langListVisible, setlangListVisible] = useState<boolean>(false);

  useEffect(() => {
    const init = (status: RequestStatus) => {
      if (status !== RequestStatus.success) return;
      const lang = getLanguage();
      if (Object.keys(LANGTYPE_MAPPING).includes(lang)) {
        setLanguage(lang);
        changeLanguage(lang);
      } else {
        setLanguage('en');
        changeLanguage('en');
      }
    };
    init(RequestStatus.success);
    event.on(COMMON_EVENT_NAMES.i18nInit, init);

    return () => {
      event.off(COMMON_EVENT_NAMES.i18nInit);
    };
  }, []);

  const history = useHistory();
  useDocumentTitle(t('title', 'JoyMi'));

  const handleLangChange = (lang: string) => {
    setLanguage(lang);
    setlangListVisible(false);
    changeLanguage(lang);
  };
  const ref = React.useRef<HTMLDivElement>(null);
  useClickAway(() => {
    setlangListVisible(false);
  }, ref);

  return (
    <div className={styles.home}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <img
              src={
                window.location.href.includes('web.joymiweb.com')
                  ? require('./assets/logo_ios.png')
                  : require('./assets/logo_img.png')
              }
              alt={'JoyMi'}
            />
          </div>
          <div className={styles.headerRight}>
            <Button
              type={ButtonTypes.primary}
              className={styles.rechargeBtn}
              onClick={() => {
                window.location.href = 'https://www.joymi.live/pay/index.html#/pay';
              }}
            >
              <span>{t('rechargeBtn', 'Recharge')}</span>
            </Button>
            <div className={styles.lang} ref={ref}>
              <div className={styles.label} onClick={() => setlangListVisible(!langListVisible)}>
                <span>{t(LANGTYPE_MAPPING[language])}</span>
                <Spacing style={{ width: '17px' }} />
                <Image
                  style={{ transform: `rotate(${langListVisible ? 180 : 0}deg)` }}
                  src={require('./assets/arrow_down_icon.png')}
                />
              </div>
              <div className={styles.langList} style={{ visibility: langListVisible ? 'visible' : 'hidden' }}>
                {Object.keys(LANGTYPE_MAPPING).map(
                  (key) =>
                    language !== key && (
                      <div key={key} onClick={() => handleLangChange(key)}>
                        {t(LANGTYPE_MAPPING[key])}
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.title}>{t('title1', 'Grup sesli sohbet odaları')}</div>
            <div className={styles.subTitle}>{t('title2', 'Arkadaşlarla tanışın ve partinin tadını çıkarın')}</div>
            <div className={styles.download}>
              <div
                className={styles.btn}
                onClick={() => {
                  window.location.href = APP_LINK.googlePlay;
                }}
              >
                <Image
                  className={styles.googlePlayIcon}
                  width={30}
                  height={34}
                  src={require('./assets/google_play_icon.png')}
                />
                <Spacing style={{ width: '13px' }} />
                <span>Google Play</span>
              </div>
              <Spacing style={{ width: '24px' }} />
              <div
                className={styles.btn}
                onClick={() => {
                  window.location.href = APP_LINK.appStore;
                }}
              >
                <Image className={styles.appStoreIcon} width={31} height={35} src={require('./assets/ios_icon.png')} />
                <Spacing style={{ width: '13px' }} />
                <span>App Store</span>
              </div>
              {!['www.joymiweb.com', 'joymiweb.com'].includes(window.location.host) && (
                <React.Fragment>
                  <Spacing style={{ width: '24px' }} />
                  <div
                    className={styles.btn}
                    onClick={() => {
                      window.location.href = `${APP_LINK.androidAPK}?v=${Date.now()}`;
                    }}
                  >
                    <Image className={styles.apkIcon} width={21} height={36} src={require('./assets/apk_icon.png')} />
                    <Spacing style={{ width: '22px' }} />
                    <span>APK</span>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.swiperBg} />
            <div className={styles.swiperBox}>
              <Swiper
                autoplay
                loop
                style={{
                  borderRadius: '16px',
                }}
                defaultIndex={0}
              >
                {SWIPER_IMAGES.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      <img className={styles.swiperImg} src={item} />
                    </Swiper.Item>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>

        <footer className={styles.footer}>
          <div>
            <span
              className={styles.linkBtn}
              onClick={() => {
                history.push('/contact');
              }}
            >
              {t('contactus', 'Bizimle iletişime geç')}
            </span>
            <span className={styles.divide} />
            <span
              className={styles.linkBtn}
              onClick={() => {
                history.push('/user-agreement');
              }}
            >
              {t('userAgreement', 'Kullanıcı Sözleşmesi')}
            </span>
            <span className={styles.divide} />
            <span
              className={styles.linkBtn}
              onClick={() => {
                history.push('/privacy-policy');
              }}
            >
              {t('privacyPolicy', 'Gizlilik Politikası')}
            </span>
          </div>
          <div className={styles.copyright}>{t('copyright', 'Copyright ©2023 JoyMi')}</div>
        </footer>
      </div>
    </div>
  );
};

export default React.memo(Home);
