import './index.less';

import _Toast from 'antd-mobile/es/components/toast';
import { ToastShowProps } from 'antd-mobile/es/components/toast/methods';

const { show } = _Toast;

_Toast.show = (args: ToastShowProps | string) => {
  Toast.clear();
  return show(args);
};

// @ts-ignore
window._fallaMicro_toast = (window._fallaMicro_toast as typeof _Toast) || _Toast;

// @ts-ignore
export const Toast: typeof _Toast = window._fallaMicro_toast;

export default Toast;
