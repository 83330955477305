import { getCssBackgroundImage, px2rem } from '@falla/utils/src/format-util';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';

import styles from './index.module.less';

export type ActivityButtonProps = PropsWithBaseProps<{
  backgroundImage?: string;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  supportDisabledClick?: boolean;
  onDisabledClick?: (event: React.MouseEvent) => void;
}>;

const ActivityButton: React.FC<ActivityButtonProps> = (props) => {
  const { backgroundImage, width, height, supportDisabledClick } = props;

  const handlerClick = React.useMemo(() => {
    return debounce(
      (e: React.MouseEvent) => {
        if (props.disabled) return props.onDisabledClick && props.onDisabledClick(e);
        props.onClick && props.onClick(e);
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    );
  }, [props.disabled, props.onDisabledClick, props.onClick]);

  return (
    <button
      className={classNames(styles.activityButton, props.className, { [styles.isDisabled]: props.disabled })}
      disabled={!supportDisabledClick && props.disabled}
      style={{
        ...(props.style || {}),
        backgroundImage: backgroundImage ? getCssBackgroundImage(backgroundImage) : undefined,
        backgroundSize: backgroundImage ? '100% 100%' : undefined,
        width: px2rem(width),
        height: px2rem(height),
      }}
      onClick={handlerClick}
    >
      {props.children}
    </button>
  );
};

export default ActivityButton;
